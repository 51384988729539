import React from "react"
import PropTypes from "prop-types"
import { getSrc } from "gatsby-plugin-image"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

// eslint-disable-next-line
export const BobIntegrationTemplate = ({
  content,
  contentComponent,
  description,
  title,
}) => {
  const PostContent = contentComponent || Content;
  return (
      <>
        <section>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="HRLola"/>
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description ?? `Article on Kamsa - ${title}`}/>
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description ?? `Article on Kamsa - ${title}`}/>
          </Helmet>
        </section>
        <section className="section insights-container">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-8">
                <div className="block has-text-centered mb-6 px-6 no-padding-mobile">
                  <h2 className="subtitle is-h2">{ title }</h2>
                  { description ?? <h4 className="is-h4">{ description }</h4> }
                </div>

                <PostContent content={content} className={'bob-integration'} />

              </div>
            </div>
          </div>
        </section>
      </>
  );
};

BobIntegrationTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  header: PropTypes.string,
  title: PropTypes.string,
};

const BobIntegration = ({ data }) => {
  const { markdownRemark } = data

  return (
    <Layout>
      <BobIntegrationTemplate
        content={markdownRemark.html}
        contentComponent={HTMLContent}
        header={markdownRemark.frontmatter.header}
        description={markdownRemark.frontmatter.description}
        title={markdownRemark.frontmatter.title}
      />
    </Layout>
  );
};

BobIntegration.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BobIntegration;

export const bobIntegrationQuery = graphql`
  query BobIntegration($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
